import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-4" }
const _hoisted_4 = { class: "col-xxl-4" }
const _hoisted_5 = { class: "row gy-5 g-xl-8" }
const _hoisted_6 = { class: "col-xxl-4" }
const _hoisted_7 = { class: "col-xxl-4" }
const _hoisted_8 = { class: "col-xxl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecentOrdersWidget = _resolveComponent("RecentOrdersWidget")!
  const _component_MonthlyOrdersCountWidget = _resolveComponent("MonthlyOrdersCountWidget")!
  const _component_MonthlyOrdersSumWidget = _resolveComponent("MonthlyOrdersSumWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RecentOrdersWidget, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
          branchId: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RecentOrdersWidget, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
          branchId: 2
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_RecentOrdersWidget, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
          branchId: 3
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_MonthlyOrdersCountWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "160",
          branchId: 1
        }),
        _createVNode(_component_MonthlyOrdersSumWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "160",
          branchId: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MonthlyOrdersCountWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "160",
          branchId: 2
        }),
        _createVNode(_component_MonthlyOrdersSumWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "160",
          branchId: 2
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_MonthlyOrdersCountWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "160",
          branchId: 3
        }),
        _createVNode(_component_MonthlyOrdersSumWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "160",
          branchId: 3
        })
      ])
    ])
  ], 64))
}