
import { defineComponent, onMounted } from "vue";
import { getRecentOrdersRequest } from "@/sea/apis/StatisticsService";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import { NumberUtils } from "@/sea/utils/NumberUtils";

export default defineComponent({
  name: "recent-orders-widget",
  props: {
    widgetClasses: String,
    branchId: Number,
  },
  components: {},
  setup(props) {
    const { loading, ordersData, getRecentOrders } = getRecentOrdersRequest();

    const getOrderType = (type) => {
      if (type == 1) {
        return I18nUtils.translate("sea.widgets.recentOrders.pickUp");
      } else if (type == 2) {
        return I18nUtils.translate("sea.widgets.recentOrders.delivery");
      } else {
        return "";
      }
    };

    const getOrderColor = (order) => {
      // status: Pending = 1, Processing = 2, Processed = 3, Ready = 4, Shipping = 5, Completed = 6, Cancelled = 7
      // payment method: Cash = 1, Eftpos = 2, PaymentExpress = 3
      // payment status: Unpaid = 1, Paid = 2

      let color;

      if (order.status == 1) {
        if (order.paymentMethod != 3) {
          color = "text-primary";
        } else {
          if (order.paymentStatus == 2) {
            color = "text-warning";
          } else {
            color = "text-primary";
          }
        }
      } else if (order.status == 6) {
        color = "text-success";
      } else if (order.status == 7) {
        color = "text-danger";
      } else {
        color = "text-warning";
      }

      return color;
    };

    const refresh = async () => {
      await getRecentOrders(props.branchId ?? 0, 10, true);
    };

    onMounted(async () => {
      await getRecentOrders(props.branchId ?? 0, 10);
    });

    return {
      I18nUtils,
      DateTimeUtils,
      NumberUtils,
      loading,
      ordersData,
      getOrderType,
      getOrderColor,
      refresh,
    };
  },
});
