
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n/index";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import { getMonthlyOrdersRequest } from "@/sea/apis/StatisticsService";

export default defineComponent({
  name: "monthly-orders-count-widget",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    branchId: Number,
  },
  setup(props) {
    const { locale } = useI18n();
    const color = ref(props.chartColor);

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);

    const generateChartOptions = (yCat) => {
      return {
        chart: {
          fontFamily: "inherit",
          type: "bar",
          height: props.chartHeight,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["25%"],
            endingShape: "flat",
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: yCat,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          y: 0,
          offsetX: 0,
          offsetY: 0,
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          type: "solid",
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          padding: {
            top: 10,
          },
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      };
    };

    const chartOptions = ref(
      generateChartOptions(["Month", "Month", "Month", "Month", "Month"])
    );

    const series = ref([
      {
        name: "",
        data: [] as number[],
      },
    ]);

    const { loading, countData, getMonthlyOrders } = getMonthlyOrdersRequest();

    const renderChart = () => {
      if (countData.value && countData.value.list) {
        series.value = [
          {
            name: I18nUtils.translateGlobal(
              "sea.widgets.monthlyOrdersCount.title"
            ),
            data: countData.value.list.map((it) => it.count).reverse(),
          },
        ];

        chartOptions.value = generateChartOptions(
          countData.value.list
            .map((it) => DateTimeUtils.parseMonth(it.month, true))
            .reverse()
        );
      }
    };

    const getDateRange = computed(() => {
      if (
        countData.value &&
        countData.value.list &&
        countData.value.list.length > 0
      ) {
        const latestMonth = countData.value.list[0];
        const earliestMonth =
          countData.value.list[countData.value.list.length - 1];
        return (
          DateTimeUtils.parseYearAndMonth(earliestMonth.month, true) +
          " - " +
          DateTimeUtils.parseYearAndMonth(latestMonth.month, true)
        );
      } else {
        return "";
      }
    });

    const refresh = async () => {
      await getMonthlyOrders("count", props.branchId ?? 0, 8, true);
      renderChart();
    };

    onMounted(async () => {
      await getMonthlyOrders("count", props.branchId ?? 0, 8);
      renderChart();
    });

    watch(locale, () => {
      renderChart();
    });

    return {
      I18nUtils,
      chartOptions,
      series,
      loading,
      countData,
      getDateRange,
      refresh,
    };
  },
});
