
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import RecentOrdersWidget from "@/sea/components/widgets/RecentOrdersWidget.vue";
import MonthlyOrdersSumWidget from "@/sea/components/widgets/MonthlyOrdersSumWidget.vue";
import MonthlyOrdersCountWidget from "@/sea/components/widgets/MonthlyOrdersCountWidget.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    MonthlyOrdersSumWidget,
    MonthlyOrdersCountWidget,
    RecentOrdersWidget,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("sea.pages.dashboard.title");
    });
  },
});
