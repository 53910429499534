import { ref } from "vue";
import { AxiosResponse } from "axios";
import ApiService from "@/core/services/ApiService";
import { GetMonthlyOrdersTypeCountResponse } from "@/sea/apis/models/statistics/GetMonthlyOrdersTypeCountResponse";
import { GetMonthlyOrdersTypeSumResponse } from "@/sea/apis/models/statistics/GetMonthlyOrdersTypeSumResponse";

export const getRecentOrdersRequest = () => {
  const ordersData = ref(null);
  const response = ref<AxiosResponse>();
  const error = ref(null);
  const loading = ref(false);

  const getRecentOrders = async (
    branchId: number | null,
    count: number | null,
    clear = false
  ) => {
    loading.value = true;

    if (clear) {
      ordersData.value = null;
    }

    try {
      ApiService.setHeader();
      const result = await ApiService.get("/statistics/recent-orders", {
        params: {
          branchId: branchId,
          count: count,
        },
      });
      response.value = result;
      ordersData.value = result.data;
    } catch (ex) {
      error.value = ex.message;
    } finally {
      loading.value = false;
    }
  };

  return { response, error, ordersData, loading, getRecentOrders };
};

export const getMonthlyOrdersRequest = () => {
  const countData = ref<GetMonthlyOrdersTypeCountResponse | null>(null);
  const sumData = ref<GetMonthlyOrdersTypeSumResponse | null>(null);
  const response = ref<AxiosResponse>();
  const error = ref(null);
  const loading = ref(false);

  const getMonthlyOrders = async (
    type: string,
    branchId: number | null,
    count: number | null,
    clear = false
  ) => {
    loading.value = true;

    if (clear) {
      if (type == "count") {
        countData.value = null;
      } else if (type == "sum") {
        sumData.value = null;
      }
    }

    try {
      ApiService.setHeader();
      const result = await ApiService.get(
        `/statistics/monthly-orders/${type}`,
        {
          params: {
            branchId: branchId,
            count: count,
          },
        }
      );
      response.value = result;
      if (type == "count") {
        countData.value = result.data;
      } else if (type == "sum") {
        sumData.value = result.data;
      }
    } catch (ex) {
      error.value = ex.message;
    } finally {
      loading.value = false;
    }
  };

  return { response, error, countData, sumData, loading, getMonthlyOrders };
};
