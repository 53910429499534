export class StringUtils {
  static formatAmount(val) {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(3) + "m";
    } else if (val >= 1000) {
      return (val / 1000).toFixed(3) + "k";
    } else {
      return val;
    }
  }

  static toBoolean(value: string | number | boolean | null): boolean | null {
    if (value) {
      const includedTrue = [true, "true", "True", "TRUE", "1", 1].includes(
        value
      );
      if (includedTrue) return true;

      const includedFalse = [false, "false", "False", "FALSE", "0", 0].includes(
        value
      );
      if (includedFalse) return false;

      return null;
    } else {
      return null;
    }
  }
}
