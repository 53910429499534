
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n/index";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import { StringUtils } from "@/sea/utils/StringUtils";
import { getMonthlyOrdersRequest } from "@/sea/apis/StatisticsService";

export default defineComponent({
  name: "monthly-orders-sum-widget",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    branchId: Number,
  },
  setup(props) {
    const { locale } = useI18n();
    const color = ref(props.chartColor);

    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);

    const generateChartOptions = (yCat) => {
      return {
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: props.chartHeight,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [baseColor],
        },
        xaxis: {
          categories: yCat,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              color: strokeColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return "$" + StringUtils.formatAmount(val);
            },
          },
        },
        colors: [lightColor],
        markers: {
          colors: [lightColor],
          strokeColor: [baseColor],
          strokeWidth: 3,
        },
      };
    };

    const chartOptions = ref(
      generateChartOptions(["Month", "Month", "Month", "Month", "Month"])
    );

    const series = ref([
      {
        name: "",
        data: [] as number[],
      },
    ]);

    const { loading, sumData, getMonthlyOrders } = getMonthlyOrdersRequest();

    const renderChart = () => {
      if (sumData.value && sumData.value.list) {
        series.value = [
          {
            name: I18nUtils.translateGlobal(
              "sea.widgets.monthlyOrdersSum.title"
            ),
            data: sumData.value.list.map((it) => it.sum).reverse(),
          },
        ];

        chartOptions.value = generateChartOptions(
          sumData.value.list
            .map((it) => DateTimeUtils.parseMonth(it.month, true))
            .reverse()
        );
      }
    };

    const getDateRange = computed(() => {
      if (
        sumData.value &&
        sumData.value.list &&
        sumData.value.list.length > 0
      ) {
        const latestMonth = sumData.value.list[0];
        const earliestMonth = sumData.value.list[sumData.value.list.length - 1];
        return (
          DateTimeUtils.parseYearAndMonth(earliestMonth.month, true) +
          " - " +
          DateTimeUtils.parseYearAndMonth(latestMonth.month, true)
        );
      } else {
        return "";
      }
    });

    const refresh = async () => {
      await getMonthlyOrders("sum", props.branchId ?? 0, 8, true);
      renderChart();
    };

    onMounted(async () => {
      await getMonthlyOrders("sum", props.branchId ?? 0, 8);
      renderChart();
    });

    watch(locale, () => {
      renderChart();
    });

    return {
      I18nUtils,
      StringUtils,
      chartOptions,
      series,
      loading,
      sumData,
      getDateRange,
      refresh,
    };
  },
});
